<template>
  <div class="card shadow border-0">
    <div class="card-body ">
      <form ref="form" name="add_place_form">
          <div class="form-group row">
                <div class="col-md-12 text-center">
                    <img id="blah" src="/static/images/placeholder-min.jpg" style="height:140px;width:140px;border:2px solid #0f7acf;border-radius:10px" >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center ">
            <label type="button" for="imgInp" class="btn btn-success btn-sm mb-3"> Upload Image </label>
             <input style="display:none" accept="image/*" type='file' id="imgInp" @change="imgPreview" >
                </div>
                <br>
            </div>
            <div class="form-group row">
                <div class="col-md-12">
                    <input type="text" class="form-control form-control-sm" v-model="obj.name" placeholder="Business Name" >
                </div>
            </div>
             <div class="form-group row">
                <div class="col-md-12" v-if="type == 'hh'">
                    <input type="text" v-model="obj.specials" class="form-control form-control-sm"  placeholder="Happy Hour Information Link">
                </div>
                <div class="col-md-12" v-if="type == 'lm'">
                    <input type="text" v-model="obj.url" class="form-control form-control-sm"  placeholder="Facebook Home Page">
                </div>
                
            </div>
            <div class="form-group row">
                <div class="col-md-6">
                    <input type="number" v-model="obj.streetNumber" class="form-control form-control-sm" id="street_number" placeholder="Street Number">
                </div>
                 <div class="col-md-6">
                    <input type="text" v-model="obj.streetName" class="form-control form-control-sm"  placeholder="Street Name">
                </div>
            </div>
            <div class="form-group row">
                 <div class="col-md-6">
                    <input type="text" v-model="obj.suitNo" class="form-control form-control-sm"  placeholder="Suite #">
                </div>
                <div class="col-md-6">
                    <input type="text" v-model="obj.city" class="form-control form-control-sm"  placeholder="City">
                </div>
            </div>
            <div class="form-group row">
                 <div class="col-md-6">
                    <input type="text" @keypress="isLetter($event)" v-model="obj.state" class="form-control form-control-sm"  placeholder="State" id="state">
                </div>
                <div class="col-md-6">
                    <input type="number" v-model="obj.zipcode" class="form-control form-control-sm"  placeholder="ZIP Code">
                </div>
                
            </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary mt-4"
            @click="storePlace"
          >
            Add Place 
          </button>
          <br>
          <br>
          <br>
          <br>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import {axios} from '../services/http-common';

export default {
    props:['type'],
	data(){
		return{
           obj:{
            image:'',
            name:'',
            url:'',
			streetNumber:'',
			streetName:'',
			city:'',
			state:'',
			suitNo:'',
			zipcode:'',
			specials:'',
           },
		}
	},
	methods:{
        isLetter(e){
            let char = String.fromCharCode(e.keyCode); 
            if(/^[A-Za-z]+$/.test(char)) return true; 
            else e.preventDefault();
        },
        storePlace(e){
            if(this.type == 'hh')
                this.storeHh();
            if(this.type == 'lm')
                this.storeLm();
        },

        storeHh(params) {
            axios
            .post(`${process.env.VUE_APP_API_URL}store-hh-place`,this.obj)
            .then(response => {
                if(response.status == 201){
                    this.$toast.success('Place Added', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
                $('#add_place').modal('toggle');
                }
            })
            .catch( error => {
            if(error.response.status == 400 ){
                this.$toast.error('Place Already Registed', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                });
            }else{
                 this.$toast.error('Something Went Wrong', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                });
            }
        });
        },
        storeLm(params) {
            axios
            .post(`${process.env.VUE_APP_API_URL}store-lm-place`,this.obj)
            .then(response => {
                if(response.status == 201){
                    this.$toast.success('Place Added', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
                $('#add_place').modal('toggle');
                }
            })
            .catch( error => {
            if(error.response.status == 400 ){
                this.$toast.error('Place Already Registed', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                });
            }else{
                 this.$toast.error('Something Went Wrong', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                });
            }
        });
        },
        imgPreview(){
            const [file] = imgInp.files
            if (file) {
              blah.src = URL.createObjectURL(file);
               this.obj.image = file;

               //image to base64
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => this.obj.image = reader.result;
                reader.onerror = error => alert('error');
            }
        },
		login(){
			let obj = {};
			obj.password = this.password;
			obj.email = this.email;
			axios
        .post(`${process.env.VUE_APP_API_URL}login`,obj)
        .then(response => {
            if (!response.error) {
              localStorage.setItem('token', response.data.message.token);
              localStorage.setItem('role', response.data.message.user_role);
              localStorage.setItem('name', response.data.message.user_name);
              if( typeof response.data.message.image !== 'undefined')
              localStorage.setItem('image', response.data.message.image);
              localStorage.setItem('user_id', response.data.message.user_id);
              localStorage.setItem('zipcode', response.data.message.zipcode);
              window.location.href = "/profile";
            }
        })
        .catch( error => {
          if(error.response)
            this.errors = error.response.data.message;
        });
		}
    },
    
};

$('#state').keydown(function (e) {
    alert('state change');
  
    if (e.shiftKey || e.ctrlKey || e.altKey) {
    
      e.preventDefault();
      
    } else {
    
      var key = e.keyCode;
      
      if (!((key == 8) || (key == 32) || (key == 46) || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
      
        e.preventDefault();
        
      }

    }
    
  });
</script>
